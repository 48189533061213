import * as React from "react"

import Seo from "../components/seo"
import Interests from "../components/pages/index/interests"
import Posts from "../components/pages/index/posts"
import Projects from "../components/pages/index/projects"

const IndexPage = () => {
  return (
    <div>
      <Seo title="Welcome" />
      <Interests />
    
      <Posts />
      <Projects />
    </div>
  )
}

export default IndexPage
