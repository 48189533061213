/**
 * References:
 * [](https://www.gatsbyjs.com/docs/recipes/querying-data/#querying-data-with-the-staticquery-component)
 * [](https://getbootstrap.com/docs/5.0/components/card/#grid-cards)
 * [](https://www.gatsbyjs.com/plugins/gatsby-plugin-image/#dynamic-images)
 */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
//import components from "../../components/styles.mdx"
import components from "../../styles.mdx"

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      posts: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {fileAbsolutePath: {regex: "/(projects)/"  }}
      )  {
        nodes {
          body
          frontmatter {
            title
            date
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.8
                  width: 832
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
            embeddedLocalImages {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      globalFeatureImage: file(
        relativePath: {eq: "pexels-alena-darmel-7862494.jpg"}
      ) {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 1.8
            width: 832
            transformOptions: {cropFocus: CENTER}
          )
        }
      }
    }
  `)

  data.posts.nodes.forEach(v=>{  
    if(v.frontmatter.featuredImage == null) {
        v.frontmatter.featuredImage = data.globalFeatureImage;
    }  
  });

  return(
    <section className="pt-5 pb-5">
      <div className="container" id="projects">
        <div className="row row-cols-1 row-cols-xl-3 g-4">
          {data.posts.nodes.map(({ frontmatter, body }) => (
            <div key={frontmatter.title} className="col-lg-4 mb-4">
              <div className="card border-0">
              <GatsbyImage className="img-fluid card-img-top" alt="100%x280"
                image={getImage(frontmatter.featuredImage)} />
                <div className="card-body">
                  <h5 className="card-title">{frontmatter.title}</h5>
                  <MDXProvider components={components}>
                    <MDXRenderer onlyExcerpt={true} localImages={frontmatter.embeddedLocalImages}>{body}</MDXRenderer>
                  </MDXProvider>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Projects