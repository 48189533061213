import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Interests = () => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: {fileAbsolutePath: {regex: "/(interests)/"  }}
          limit: 4
        )  {
          nodes {
            frontmatter {
              title
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.8
                    width: 832
                    transformOptions: {
                      cropFocus: CENTER
                    }
                  )
                }
              }
            }
            slug
          }
        }
      }
    `}
    render={( data ) => (
      <section className="pt-5 pb-5">
      <div className="container" id="about-me">
        <div className="row">

          <div className="col-lg-4">
            <div className="card border-0 w-100 h-100">
              
              <StaticImage
                className="card-img h-100 w-100"
                src="../../../images/20170810_160720.jpg"
                alt="A dinosaur" />
              <div className="card-img-overlay">
                <div className="position-relative w-100 h-25 d-flex">
                  <div className="container-fluid align-self-start">
                    
                    <h4 className="text-white">Chad Tomlinson</h4>

                  </div>
                </div>
                <div className="position-relative w-100 h-50 d-flex">
                  <div className="container-fluid align-self-center">
                    
                    <div className="row h-100 align-items-center">
                      <h2 className="text-end text-white">
                        A<br />
                        Nerd
                      </h2>
                    </div>

                  </div>
                </div>
                <div className="position-relative w-100 h-25 d-flex">
                  <div className="container-fluid align-self-end">
                    
                    <div className="text-end">
                      <a href=" " class="btn btn-outline-light">Learn more</a>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>            
          </div>


          <div className="col-lg-8 col-md-12">
            <div className="row row-cols-1 row-cols-xl-2 g-4">                
              {data.allMdx.nodes.map(({ frontmatter, slug }) => (
                <div key={frontmatter.title} className="col-lg-6 mb-6">
                  
                    <div className="card border-0">
                      <GatsbyImage className="img-fluid" alt="100%x280" image={getImage(frontmatter.featuredImage)} />
                      <div className="card-img-overlay p-1 d-flex  flex-column  align-items-baseline justify-content-center ">
                        <div className="container-fluid h-50 text-center">
                          <div className="row h-100 align-items-center">
                            <div className="col-12">
                              <h2 className="text-white">{frontmatter.title}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                </div>
              ))}      
            </div>  
          </div>

        </div>
      </div>
    </section> 
    )}
  />
)

export default Interests
